<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="closeFunc()">
          <ion-icon :icon="close"> </ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button :disabled="!(groupName.length > 0)" @click="newGroup()">
          Vytvořit
        </ion-button>
      </ion-buttons>
      <ion-title>Nová skupina</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>
      <ion-item-group
        ><ion-item-divider color="light">
          <ion-label>Základní informace</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-label position="floating">Název Skupiny*</ion-label>
          <ion-input
            required="true"
            v-model="groupName"
            :value="groupName"
          ></ion-input> </ion-item
      ></ion-item-group>
      <ion-item-group>
        <ion-item-divider color="light">
          <ion-label>Obrázek</ion-label>
        </ion-item-divider>
        <template v-if="isWeb">
          <ion-item>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/png, image/jpeg"
              ref="groupImg"
              @change="previewFiles"
            />
            <ion-icon
              v-if="groupImage != ''"
              color="primary"
              slot="end"
              @click="removeImage()"
              :icon="trash"
            ></ion-icon>
          </ion-item>
        </template>
        <template v-else>
          <ion-item>
            <ion-icon
              color="primary"
              slot="start"
              @click="loadImage()"
              :icon="addCircle"
            ></ion-icon>
            <ion-label> {{ imageName }} </ion-label>
            <ion-icon
              v-if="groupImage != ''"
              color="primary"
              slot="end"
              @click="removeImage()"
              :icon="trash"
            ></ion-icon>
          </ion-item>
        </template>
      </ion-item-group>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonChip,
  IonInput,
  IonTextarea,
  IonDatetime,
  IonList,
  IonItemDivider,
  IonItemGroup,
  IonNote,
  isPlatform,
} from "@ionic/vue";
import { close, earth, addCircle, trash } from "ionicons/icons";
import { mapGetters, mapActions } from "vuex";

import { getPicture } from "../composable/photoPicker";

export default {
  name: "NewGroupModal",
  setup() {
    return { close, earth, addCircle, trash };
  },
  watch: {},
  data() {
    return {
      groupName: "",
      startDate: "",
      endDate: "",
      descritpion: "",
      groupImage: "",
      imageName: "",
    };
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
    IonItem,
    IonLabel,
    IonList,
    IonInput,
    IonItemDivider,
    IonItemGroup,
    // IonNote,
  },
  beforeMount() {},
  methods: {
    ...mapActions({
      createNewGroup: "groups/createNewGroup",
    }),
    closeFunc() {
      modalController.dismiss({
        dismissed: true,
        created: true,
      });
    },
    newGroup() {
      let self = this;
      console.log("evnt", this.groupName);
      this.createNewGroup({
        networkId: self.getActiveId,
        name: self.groupName,
        img: self.groupImage,
      }).then((res) => {
        console.log("res");
        self.closeFunc();
      });
    },
    loadImage() {
      // console.log("Chooser", isPlatform("mobileweb"));

      getPicture().then((res) => {
        console.log("GET PICTURE RESULT", res);
        this.imageName = res.name;
        this.groupImage = res.data;
      });

      // Chooser.getFile("image/jpeg,image/png")
      //   .then((res) => {
      //     console.log(res);

      //     this.imageName = res.name;

      //     this.groupImage = new Blob([res.data], {
      //       type: res.mediaType,
      //     });
      //   })
      //   .catch((err) => {
      //     alert("Obrázek se nepodařilo nahrát");
      //   });
    },
    removeImage() {
      this.groupImage = "";
      this.imageName = "";
      if (this.isWeb) this.$refs.groupImg.value = "";
      // console.log(this.groupImage, this.imageName);
    },
    previewFiles(event) {
      this.groupImage = event.target.files[0];
      // this.loadImage();
    },
  },
  computed: {
    ...mapGetters({
      getActiveId: "social/getActiveId",
    }),
    isWeb() {
      console.log("IsWEB", isPlatform("mobileweb"), isPlatform("desktop"));
      return isPlatform("mobileweb") || isPlatform("desktop");
    },
  },
};
</script>

<style scoped>
ion-avatar {
  width: 75px;
  height: 75px;
}
ion-item-group {
  border: 1px solid var(--ion-color-medium);
  margin-top: 16px;
}
ion-list ion-item-group:first-child {
  margin-top: 0px;
}
/* ion-label {
  margin-left: 12px;
} */
.name {
  font-weight: bold;
  font-size: 16px;
  margin-left: 4px;
}
.info {
  color: #636366;
  font-size: 14px;
}
ion-item-group ion-item:last-child {
  margin-bottom: 0px;
}
ion-list {
  padding-bottom: 0px;
  padding-top: 0px;
}
ion-content {
  --ion-background-color: var(--ion-card-background);
  --background: var(--ion-card-background);
  background: var(--background);
}
</style>
