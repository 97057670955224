<template>
  <ion-card>
    <ion-card-header>
      <template v-if="editable">
        <template v-if="!editing">
          <ion-icon
            class="edit-profile"
            @click="editing = true"
            :icon="pencil"
          ></ion-icon>
        </template>
        <template v-else>
          <ion-icon
            class="edit-profile"
            @click="saveNewProfile()"
            :icon="checkmarkCircle"
          ></ion-icon>
          <ion-icon
            class="close-profile"
            @click="discardEdit()"
            :icon="close"
          ></ion-icon>
        </template>
      </template>

      <template v-if="!editing">
        <ion-avatar>
          <ion-img :src="`${avatar}`" />
        </ion-avatar>
      </template>
      <template v-else>
        <ion-avatar class="edit-picture" @click="changeProfilePic()">
          <div class="edit-overlay">
            <div class="edit-ovelay-text">Změnit</div>
            <ion-img :src="`${avatar}`" v-if="newAvatar == ''" />
            <ion-img :src="`${newAvatar}`" v-else />
          </div>
        </ion-avatar>
      </template>
    </ion-card-header>

    <ion-card-content>
      <template v-if="editing">
        <ion-input v-model="newName" class="name-input"></ion-input>
      </template>
      <template v-else
        ><h2>{{ name }}</h2>
      </template>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonInput,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCol,
  IonRow,
  IonGrid,
  IonImg,
  IonSpinner,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { pencil, checkmarkCircle, close } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import ProfilePictureSelectorModal from "./ProfilePictureSelectorModal.vue";
export default {
  name: "ProfileCard",
  data() {
    return {
      userPosts: [],
      avatar: "",
      name: "",
      postsLoaded: true,
      editing: false,
      newAvatar: "",
      newAvatarId: "",
      newName: "",
    };
  },
  props: {
    editable: Boolean,
  },
  setup() {
    return { pencil, checkmarkCircle, close };
  },
  components: {
    IonInput,
    IonAvatar,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonImg,
    IonIcon,
  },
  computed: {
    ...mapGetters({
      getUserPosts: "social/getUserPosts",
      getUserAvatarUrl: "auth/getUserAvatarUrl",
      getUserAvatarId: "auth/getUserAvatarId",
      getUserName: "auth/getUserName",
      getActiveId: "social/getActiveId",
      isLoaded: "social/isLoaded",
      getUserId: "auth/getUserId",
    }),
  },
  created() {},
  beforeMount() {
    let self = this;

    this.avatar = this.getUserAvatarUrl;
    this.name = this.getUserName;
    this.newName = this.name;
    this.newAvatarId = this.getUserAvatarId;
  },
  methods: {
    ...mapActions({
      updateUserInfo: "auth/updateUserInfo",
    }),
    async changeProfilePic() {
      let self = this;
      // alert("changePic");
      const modal = await modalController.create({
        component: ProfilePictureSelectorModal,
        cssClass: "profile-pic-modal",
        componentProps: {
          selectedAvatar: self.newAvatar != "" ? self.newAvatar : self.avatar,
        },
      });

      modal.present();

      modal.onDidDismiss().then((data) => {
        if (data != undefined && data.data.selectedId) {
          // debugger;
          this.newAvatar = data.data.selectedUrl;
          this.newAvatarId = data.data.selectedId;
        } else {
          this.newAvatar = this.avatar;
        }
      });
    },
    saveNewProfile() {
      console.log(
        "Profile changes saved",
        this.newName,
        this.newAvatarId,
        this.newAvatar
      );

      //API CALL

      this.updateUserInfo({
        name: this.newName,
        avatar: this.newAvatarId,
      }).then((res) => {
        let user = res.data.user;
        this.avatar = user.avatar_url;
        this.name = user.name;
        this.discardEdit();
      });
    },
    discardEdit() {
      this.newAvatar = "";
      this.newAvatarId = "";
      this.newName = this.name;
      this.editing = false;
    },
  },
};
</script>

<style scoped>
ion-card {
  margin: 0px;
}
ion-card-content {
  border-radius: 0px;
  margin-top: 64px;
  margin-bottom: 24px;
  border-radius: 0px;
}
ion-card-content h2 {
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: var(--ion-text-color);
}
ion-card-header {
  background: url("../assets/img/profile_bg.jpg");
  background-size: cover;
  height: 130px;
}
ion-avatar {
  width: 100px;
  height: 100px;
  position: relative;
  bottom: -64px;
  margin: 0 auto;
  background: var(--ion-card-background);
  padding: 5px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 250px);
}
.edit-profile,
.close-profile {
  color: #fff;
  font-size: 1.5rem;
  position: absolute;
  right: 16px;
  cursor: pointer;
  font-size: bold;
}
.close-profile {
  left: 16px;
  /* font-size: 1.2rem; */
}
.name-input {
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: var(--ion-text-color);
  border-radius: 5px;
  border: 2px solid var(--ion-color-primary);
}
.edit-picture {
  border: 2px solid var(--ion-color-primary);
}

.edit-ovelay-text {
  /* margin: 0 auto; */
  position: absolute;
  color: var(--ion-color-primary);
  text-transform: uppercase;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  border: 2px solid var(--ion-color-primary);
  border-radius: 5px;
  background-color: var(--ion-color-medium-rgb);
  z-index: 20;
}
</style>
