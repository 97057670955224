<template>
  <ion-item
    lines="none"
    className="post-header ion-no-margin ion-no-padding"
    @click="() => $router.push(`/chat/${id}`)"
  >
    <span v-if="avatar">
      <ion-avatar class="avatar-wrapper" slot="start">
        <ion-img :src="`${avatar}`" />
      </ion-avatar>
    </span>
    <span v-else>
      <ion-icon
        class="avatar-wrapper"
        slot="start"
        :icon="peopleCircle"
      ></ion-icon>
    </span>
    <ion-label>
      <h3 class="name">{{ names }}</h3>
      <p>Poslední zpráva: {{ formattedDate }}</p>
    </ion-label>
    <ion-icon slot="end" :icon="chevronForward"></ion-icon>
  </ion-item>
</template>

<script>
import { IonItem, IonAvatar, IonLabel, IonIcon, IonImg } from "@ionic/vue";
import { peopleCircle, chevronForward } from "ionicons/icons";
import moment from "moment";

export default {
  name: "ChatItem",
  setup() {
    return {
      peopleCircle,
      chevronForward,
    };
  },
  props: {
    id: Number,
    avatar: String,
    names: String,
    time: Number,
  },
  components: {
    IonItem,
    IonAvatar,
    IonLabel,
    IonIcon,
    IonImg,
  },
  computed: {
    formattedDate() {
      return moment
        .unix(this.time)
        .locale("cs")
        .fromNow();
    },
  },
  methods: {
    openChat(id) {
      alert(id);
    },
  },
};
</script>

<style scoped>
.avatar-wrapper {
  color: var(--ion-color-primary);
  width: 48px;
  height: 48px;
  margin: 5px 10px;
}
.name {
  /* font-size: 18px; */
  font-weight: 700;
}
</style>
