<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="closeFunc()">
          <ion-icon :icon="close"> </ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button
          :disabled="!(eventName.length > 0 && startDate.length > 0)"
          @click="newEvent()"
        >
          Vytvořit
        </ion-button>
      </ion-buttons>
      <ion-title>Nová událost</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>
      <ion-item-group>
        <ion-item-divider color="light">
          <ion-label>Zakladní informace</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-label position="floating">Název události*</ion-label>
          <ion-input
            required="true"
            v-model="eventName"
            :value="eventName"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Popis události</ion-label>
          <ion-textarea
            rows="4"
            :value="descritpion"
            v-model="descritpion"
            placeholder="Zde popište k jaké příležitosti se údálost váže."
          ></ion-textarea>
        </ion-item>
      </ion-item-group>
      <ion-item-group>
        <ion-item-divider color="light">
          <ion-label>Časové informace</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-label position="floating">Začátek události*</ion-label>
          <ion-datetime
            :value="startDate"
            v-model="startDate"
            cancelText="Zrušit"
            doneText="Ok"
            min="2021-01"
            max="2100"
            display-format=" DD.MMM.YYYY HH:mm"
            month-short-names="1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12"
          ></ion-datetime>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Konec události</ion-label>
          <ion-datetime
            :value="endDate"
            v-model="endDate"
            cancelText="Zrušit"
            doneText="Ok"
            min="2021-01"
            max="2100"
            display-format=" DD.MMM.YYYY HH:mm"
            month-short-names="1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12"
          ></ion-datetime>
        </ion-item>
      </ion-item-group>
      <ion-item-group>
        <ion-item-divider color="light">
          <ion-label>Obrázek</ion-label>
        </ion-item-divider>
        <template v-if="isWeb">
          <ion-item>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/png, image/jpeg"
              ref="eventImg"
              @change="previewFiles"
            />
            <ion-icon
              v-if="eventImage != ''"
              color="primary"
              slot="end"
              @click="removeImage()"
              :icon="trash"
            ></ion-icon>
          </ion-item>
        </template>
        <template v-else>
          <ion-item>
            <ion-icon
              color="primary"
              slot="start"
              @click="loadImage()"
              :icon="addCircle"
            ></ion-icon>
            <ion-label> {{ imageName }} </ion-label>
            <ion-icon
              v-if="eventImage != ''"
              color="primary"
              slot="end"
              @click="removeImage()"
              :icon="trash"
            ></ion-icon>
          </ion-item>
        </template>
      </ion-item-group>
    </ion-list>
  </ion-content>
</template>

<script>
import moment from "moment";
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonChip,
  IonInput,
  IonTextarea,
  IonDatetime,
  IonList,
  isPlatform,
  IonNote,
  IonItemDivider,
  IonItemGroup,
} from "@ionic/vue";

import { close, earth, addCircle, trash } from "ionicons/icons";
import { mapGetters, mapActions } from "vuex";

import { getPicture } from "../composable/photoPicker";

export default {
  name: "NewEventModal",
  setup() {
    return { close, earth, addCircle, trash };
  },
  watch: {},
  data() {
    return {
      eventName: "",
      startDate: "",
      endDate: "",
      descritpion: "",
      eventImage: "",
      imageName: "",
    };
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
    IonItem,
    IonLabel,
    IonTextarea,
    IonDatetime,
    IonList,
    IonInput,
    IonItemDivider,
    IonItemGroup,
  },
  beforeMount() {
    this.avatar = this.getUserAvatarUrl;
    this.name = this.getUserName;

    this.startDate = moment().format("YYYY-MM-DD HH:mm");
    console.log("date", this.date);
  },
  mounted() {},
  methods: {
    ...mapActions({
      createNewEvent: "events/createNewEvent",
    }),
    closeFunc() {
      modalController.dismiss({
        dismissed: true,
        created: true,
      });
    },
    newEvent() {
      let self = this;
      console.log("evnt", this.eventName);
      let eDate = "";
      if (this.endDate != "") {
        eDate = moment(self.endDate).format("YYYY-MM-DD HH:mm");
      }
      this.createNewEvent({
        networkId: self.getActiveId,
        startDate: moment(self.startDate).format("YYYY-MM-DD HH:mm"),
        endDate: eDate,
        name: self.eventName,
        descritpion: self.descritpion,
        img: self.eventImage,
      }).then((res) => {
        console.log("res");
        self.closeFunc();
      });
    },
    loadImage() {
      getPicture().then((res) => {
        console.log("GET PICTURE RESULT", res);
        this.imageName = res.name;
        this.eventImage = res.data;
      });

      // console.log("Chooser", isPlatform("mobileweb"));
      // Chooser.getFile("image/jpeg,image/png")
      //   .then((res) => {
      //     console.log(res);
      //     this.imageName = res.name;
      //     this.eventImage = new Blob([res.data], {
      //       type: res.mediaType,
      //     });
      //   })
      //   .catch((err) => {
      //     alert("Obrázek se nepodařilo nahrát");
      //   });
    },
    removeImage() {
      this.eventImage = "";
      this.imageName = "";
      if (this.isWeb) this.$refs.eventImg.value = "";
    },
    previewFiles(event) {
      this.eventImage = event.target.files[0];
    },
  },
  computed: {
    ...mapGetters({
      getUserAvatarUrl: "auth/getUserAvatarUrl",
      getUserName: "auth/getUserName",
      getActiveId: "social/getActiveId",
    }),
    isWeb() {
      console.log("IsWEB", isPlatform("mobileweb"), isPlatform("desktop"));
      return isPlatform("mobileweb") || isPlatform("desktop");
    },
  },
};
</script>

<style scoped>
ion-item-group {
  border: 1px solid var(--ion-color-medium);
  margin-top: 16px;
}
ion-list ion-item-group:first-child {
  margin-top: 0px;
}
/* ion-label {
  margin-left: 12px;
} */
.name {
  font-weight: bold;
  font-size: 16px;
  margin-left: 4px;
}
.info {
  color: #636366;
  font-size: 14px;
}
ion-item-group ion-item:last-child {
  margin-bottom: 0px;
}
ion-list {
  padding-bottom: 0px;
  padding-top: 0px;
}
ion-content {
  --ion-background-color: var(--ion-card-background);
  --background: var(--ion-card-background);
  background: var(--background);
}
</style>
