<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="closeFunc()">
          <ion-icon :icon="close"> </ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Změnit profilový obrázek</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-grid
      ><ion-row>
        <ion-col
          size="6"
          size-md="6"
          size-lg="4"
          size-xl="2"
          v-for="item in avatars"
          :key="item.id"
          @click="selectAvatar(item)"
        >
          <ion-avatar style="width: 100%; height: 120px"
            ><img
              :src="item.url"
              :class="{ selected: selectedAvatar == item.url }"
          /></ion-avatar>
        </ion-col> </ion-row
    ></ion-grid>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonAvatar,
  IonCol,
  IonRow,
  IonGrid,
} from "@ionic/vue";
import {
  close,
  earth,
  peopleCircleOutline,
  calendarOutline,
  addCircle,
  trash,
} from "ionicons/icons";
import { mapActions } from "vuex";

export default {
  name: "ProfilePictureSelectorModal",
  setup() {
    return {
      close,
      earth,
      peopleCircleOutline,
      calendarOutline,
      addCircle,
      trash,
    };
  },
  data() {
    return {
      avatars: [],
    };
  },
  props: {
    selectedAvatar: String,
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
    IonAvatar,
    IonCol,
    IonRow,
    IonGrid,
  },
  beforeMount() {
    let self = this;
    this.downloadAvatars().then((res) => {
      //   debugger;
      self.avatars = res;
    });
  },
  methods: {
    ...mapActions({
      downloadAvatars: "auth/downloadAvatars",
    }),
    closeFunc() {
      modalController.dismiss({
        dismissed: true,
        selectedId: null,
      });
    },
    selectAvatar(item) {
      modalController.dismiss({
        dismissed: true,
        selectedId: item.id,
        selectedUrl: item.url,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
ion-avatar {
  justify-content: center;
  display: flex;
}
ion-avatar img {
  width: 100px;
  height: 100px;
}
.selected {
  border: 3px solid var(--ion-color-primary);
}
</style>
