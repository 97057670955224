<template>
  <ProfileCard :editable="false" />
  <div v-if="isLoaded">
    <ion-grid fixed>
      <ion-row v-if="getUserPosts.length > 0">
        <ion-col
          size="12"
          size-md="12"
          size-lg="12"
          size-xl="12"
          v-for="item in getUserPosts"
          :key="item.id"
        >
          <Post
            :avatar="item.avatar_url"
            :name="item.name"
            :id="item.id"
            :time="item.created_at"
            :text="item.text"
            :likes="item.likes"
            :isLiked="item.is_liked"
            :comments="item.comments"
            :numOfComments="item.comments.length"
            :postImg="item.media[0]"
          />
        </ion-col>
      </ion-row>
      <ion-row v-else>
        <ion-col size="12">
          <h2>Doposud jsi žadný příspěvek nepřidal.</h2>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div v-else class="loader">
    <ion-spinner></ion-spinner>
  </div>
</template>

<script>
import { IonCol, IonRow, IonGrid, IonSpinner } from "@ionic/vue";
import { pencil, checkmarkCircle, close } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import Post from "./Post.vue";
import ProfileCard from "./ProfileCard.vue";
export default {
  data() {
    return {
      userPosts: [],
      avatar: "",
      name: "",
      postsLoaded: true,
      editing: false,
      newAvatar: "",
      newAvatarId: "",
      newName: "",
    };
  },

  setup() {
    return { pencil, checkmarkCircle, close };
  },
  components: {
    Post,
    ProfileCard,
    IonCol,
    IonRow,
    IonGrid,
    IonSpinner,
  },
  computed: {
    ...mapGetters({
      getUserPosts: "social/getUserPosts",
      getUserAvatarUrl: "auth/getUserAvatarUrl",
      getUserName: "auth/getUserName",
      getActiveId: "social/getActiveId",
      isLoaded: "social/isLoaded",
      getUserId: "auth/getUserId",
    }),
  },
  created() {},
  beforeMount() {
    let self = this;
    this.downloadUserPosts({ id: this.getActiveId });

    this.avatar = this.getUserAvatarUrl;
    this.name = this.getUserName;
    this.newName = this.name;
  },
  methods: {
    ...mapActions({
      likePost: "social/likePost",
      downloadUserPosts: "social/downloadUserPosts",
    }),
  },
};
</script>

<style scoped></style>
