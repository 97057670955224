<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-segment :value="currentView" swipeGesture="false">
          <ion-segment-button @click="changeView('posts')" value="posts">
            <ion-icon :icon="newspaperOutline"></ion-icon>
            <ion-badge v-if="getNewPostCount > 0" color="danger">{{
              getNewPostCount
            }}</ion-badge>
          </ion-segment-button>
          <ion-segment-button @click="changeView('profile')" value="profile">
            <ion-icon :icon="personOutline"></ion-icon>
          </ion-segment-button>
          <ion-segment-button @click="changeView('groups')" value="groups">
            <ion-icon :icon="peopleOutline"></ion-icon>
            <ion-badge v-if="getNewGroupCount > 0" color="danger">{{
              getNewGroupCount
            }}</ion-badge>
          </ion-segment-button>
          <ion-segment-button @click="changeView('events')" value="events">
            <ion-icon :icon="calendarOutline"></ion-icon>
            <ion-badge v-if="getNewEventCount > 0" color="danger">{{
              getNewEventCount
            }}</ion-badge>
          </ion-segment-button>
          <ion-segment-button @click="changeView('chat')" value="chat">
            <ion-icon :icon="chatbubbleOutline"></ion-icon>
            <ion-badge v-if="getNewMsgCount > 0" color="danger">{{
              getNewMsgCount
            }}</ion-badge>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>

    <ion-content class="background">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content> </ion-refresher-content>
      </ion-refresher>
      <div v-if="currentView == 'posts'" style="height: 100%">
        <div v-if="isLoaded">
          <ion-grid fixed>
            <ion-row>
              <ion-col size="12" size-md="12" size-lg="12" size-xl="12">
                <h2>Příspěvky</h2>
              </ion-col>
              <ion-col
                size="12"
                size-md="12"
                size-lg="12"
                size-xl="12"
                v-for="item in getActivePosts"
                :key="item.id"
              >
                <Post
                  :avatar="item.avatar_url"
                  :name="item.name"
                  :id="item.id"
                  :time="item.created_at"
                  :text="item.text"
                  :likes="item.likes"
                  :isLiked="item.is_liked"
                  :comments="item.comments"
                  :numOfComments="item.comments.length"
                  :postImg="item.media[0]"
                />
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div v-else class="loader">
          <ion-spinner></ion-spinner>
        </div>
      </div>
      <div v-else-if="currentView == 'profile'">
        <Profile />
      </div>
      <div v-else-if="currentView == 'groups'">
        <ion-grid fixed>
          <ion-row>
            <ion-col size="4" size-md="4" size-lg="4" size-xl="4">
              <h2>Skupiny</h2>
            </ion-col>
            <ion-col
              size="8"
              size-md="8"
              size-lg="8"
              size-xl="8"
              class="chip-controls"
            >
              <ion-chip
                :class="groupsView == 'users' ? 'selected-chip' : ''"
                :color="groupsView == 'users' ? 'primary' : ''"
                @click="groupsView = 'users'"
                :outline="groupsView == 'users' ? 'false' : 'true'"
              >
                <ion-label>Vaše skupiny</ion-label>
              </ion-chip>
              <ion-chip
                :class="groupsView == 'explore' ? 'selected-chip' : ''"
                :color="groupsView == 'explore' ? 'primary' : ''"
                @click="groupsView = 'explore'"
                :outline="groupsView == 'explore' ? 'false' : 'true'"
              >
                <ion-label>Objevujte</ion-label>
              </ion-chip>
            </ion-col>
            <template v-if="groupsView == 'explore'">
              <ion-col
                size="12"
                size-md="6"
                size-lg="4"
                size-xl="4"
                v-for="group in getDiscoverGroups"
                :key="group.id"
              >
                <GroupCard
                  :id="group.id"
                  :name="group.name"
                  :isMember="group.is_member"
                  :members="group.count_members"
                  :image="group.media"
                  :inList="true"
                />
              </ion-col>
            </template>
            <template v-else>
              <ion-col
                size="12"
                size-md="6"
                size-lg="4"
                size-xl="4"
                v-for="group in getUserGroups"
                :key="group.id"
              >
                <GroupCard
                  :id="group.id"
                  :name="group.name"
                  :isMember="group.is_member"
                  :members="group.count_members"
                  :image="group.media"
                  :inList="true"
                />
              </ion-col>
            </template>
          </ion-row>
        </ion-grid>
      </div>
      <div v-else-if="currentView == 'events'">
        <ion-grid fixed>
          <ion-row>
            <ion-col size="4" size-md="4" size-lg="4" size-xl="4">
              <h2>Události</h2>
            </ion-col>
            <ion-col
              size="8"
              size-md="8"
              size-lg="8"
              size-xl="8"
              class="chip-controls"
            >
              <ion-chip
                :class="eventView == 'users' ? 'selected-chip' : ''"
                :color="eventView == 'users' ? 'primary' : ''"
                @click="eventView = 'users'"
                :outline="eventView == 'users' ? 'false' : 'true'"
              >
                <ion-label>Vaše události</ion-label>
              </ion-chip>
              <ion-chip
                :class="eventView == 'explore' ? 'selected-chip' : ''"
                :color="eventView == 'explore' ? 'primary' : ''"
                @click="eventView = 'explore'"
                :outline="eventView == 'explore' ? 'false' : 'true'"
              >
                <ion-label>Objevujte</ion-label>
              </ion-chip>
            </ion-col>
            <template v-if="eventView == 'explore'">
              <ion-col
                size="12"
                size-md="6"
                size-lg="4"
                size-xl="4"
                v-for="event in getDiscoverEvents"
                :key="event.id"
              >
                <EventCard
                  :id="event.id"
                  :name="event.name"
                  :startDate="event.date_start"
                  :endDate="event.date_end"
                  :participate="event.count_participate"
                  :interested="event.count_interest"
                  :showDetails="false"
                  :description="event.description"
                  :isInterested="event.member_interest"
                  :isParticipate="event.member_participate"
                  :image="event.media"
                  :inList="true"
                />
              </ion-col>
            </template>
            <template v-else>
              <ion-col
                size="12"
                size-md="6"
                size-lg="4"
                size-xl="4"
                v-for="event in getUserEvents"
                :key="event.id"
              >
                <EventCard
                  :id="event.id"
                  :name="event.name"
                  :startDate="event.date_start"
                  :endDate="event.date_end"
                  :participate="event.count_participate"
                  :interested="event.count_interest"
                  :showDetails="false"
                  :description="event.description"
                  :isInterested="event.member_interest"
                  :isParticipate="event.member_participate"
                  :image="event.media"
                  :inList="true"
                />
              </ion-col>
            </template>
          </ion-row>
        </ion-grid>
      </div>
      <div v-else-if="currentView == 'chat'">
        <ion-grid fixed>
          <ion-row>
            <ion-col size="12" size-md="12" size-lg="12" size-xl="12">
              <h2>Chat</h2>
            </ion-col>
            <template v-if="getListofChats.length > 0">
              <ion-col
                size="12"
                size-md="12"
                size-lg="12"
                size-xl="12"
                v-for="item in getListofChats"
                :key="item.id"
              >
                <ChatItem
                  :avatar="item.avatarUrl"
                  :names="item.name"
                  :time="item.last_message"
                  :id="item.id"
                />
              </ion-col>
            </template>
            <template v-else>
              <ion-col size="12" size-md="12" size-lg="12" size-xl="12">
                <ion-item lines="none">
                  <ion-label>
                    <h2>Není tu žadný aktivní chat.</h2>
                    <p>Můžeš založit nový ikonou bublin v pravo dole...</p>
                  </ion-label>
                </ion-item>
              </ion-col>
            </template>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <ion-fab v-if="currentView == 'posts'" vertical="bottom" horizontal="end">
      <ion-fab-button @click="createNewPost()">
        <ion-icon :icon="pencil"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-fab v-if="currentView == 'chat'" vertical="bottom" horizontal="end">
      <ion-fab-button @click="createNewChat()">
        <ion-icon :icon="chatbubbles"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-fab v-if="currentView == 'events'" vertical="bottom" horizontal="end">
      <ion-fab-button @click="createNewEvent()">
        <ion-icon :icon="calendarNumber"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-fab v-if="currentView == 'groups'" vertical="bottom" horizontal="end">
      <ion-fab-button @click="createNewGroup()">
        <ion-icon :icon="people"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonContent,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonHeader,
  IonIcon,
  IonFabButton,
  IonFab,
  IonSpinner,
  IonLabel,
  IonChip,
  IonItem,
  modalController,
  toastController,
  IonBadge,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { emitter } from "../eventBus";
import Post from "../components/Post.vue";
import Profile from "../components/Profile.vue";
import NewPostModal from "../components/NewPostModal.vue";
import NewChatModal from "../components/NewChatModal.vue";
import NewEventModal from "../components/NewEventModal.vue";
import NewGroupModal from "../components/NewGroupModal.vue";
import ChatItem from "../components/ChatItem.vue";
import EventCard from "../components/EventCard.vue";
import GroupCard from "../components/GroupCard.vue";
import {
  pencil,
  chatbubbles,
  personOutline,
  newspaperOutline,
  chatbubbleOutline,
  peopleOutline,
  calendarOutline,
  calendarNumber,
  people,
} from "ionicons/icons";

export default {
  components: {
    IonPage,
    IonGrid,
    IonCol,
    IonRow,
    IonContent,
    Post,
    Profile,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonHeader,
    IonIcon,
    IonFabButton,
    IonFab,
    IonSpinner,
    ChatItem,
    IonLabel,
    IonChip,
    EventCard,
    GroupCard,
    IonItem,
    IonBadge,
    IonRefresher,
    IonRefresherContent,
  },
  data() {
    return {
      // id: 1,
      posts: [],
      currentView: "posts",
      postsLoaded: false,
      eventView: "users",
      groupsView: "users",
    };
  },
  // props: {
  //   refresh: Boolean,
  // },
  setup() {
    return {
      pencil,
      chatbubbles,
      personOutline,
      newspaperOutline,
      chatbubbleOutline,
      peopleOutline,
      calendarOutline,
      calendarNumber,
      people,
    };
  },
  watch: {
    currentView() {
      if (this.currentView == "posts") {
        this.downloadSocialDetail({ id: this.getActiveId });
        this.changeCounter({ type: "newPost", action: "reset" });
      } else if (this.currentView == "chat") {
        this.downloadAllChats({ networkId: this.getActiveId });
        this.changeCounter({ type: "newMsg", action: "reset" });
      } else if (this.currentView == "profile") {
        // this.downloadAllChats({ networkId: this.getActiveId });
      } else if (this.currentView == "events") {
        this.downloadAllEvents({ networkId: this.getActiveId });
        this.changeCounter({ type: "newEvent", action: "reset" });
      } else if (this.currentView == "groups") {
        this.downloadAllGroups({ networkId: this.getActiveId });
        this.changeCounter({ type: "newGroup", action: "reset" });
      }
    },
  },
  computed: {
    ...mapGetters({
      getActivePosts: "social/getActivePosts",
      isLoaded: "social/isLoaded",
      getActiveId: "social/getActiveId",
      getListofChats: "chat/getListofChats",
      getDiscoverEvents: "events/getDiscoverEvents",
      getUserEvents: "events/getUserEvents",
      getDiscoverGroups: "groups/getDiscoverGroups",
      getUserGroups: "groups/getUserGroups",
      getNewPostCount: "notifications/getNewPostCount",
      getNewGroupCount: "notifications/getNewGroupCount",
      getNewEventCount: "notifications/getNewEventCount",
      getNewMsgCount: "notifications/getNewMsgCount",
    }),
  },
  created() {
    this.currentView = "posts";
    this.downloadSocialDetail({ id: this.getActiveId });
    this.downloadAllChats({ networkId: this.getActiveId });
    this.downloadAllEvents({ networkId: this.getActiveId });
    this.downloadAllGroups({ networkId: this.getActiveId });
  },
  beforeMount() {
    // emitter.on("socialNetworkChanged", (value) => {
    //   // *Listen* for event
    //   // this.currentView = "posts";
    // });
  },
  mounted() {
    console.log("all", this);
    console.log("moment", this.moment);
  },
  methods: {
    ...mapActions({
      downloadSocialDetail: "social/downloadSocialDetail",
      downloadAllChats: "chat/downloadAllChats",
      downloadAllEvents: "events/downloadAllEvents",
      downloadAllGroups: "groups/downloadAllGroups",
      changeCounter: "notifications/changeCounter",
    }),
    doRefresh(event) {
      if (this.currentView == "posts") {
        this.downloadSocialDetail({ id: this.getActiveId });
        this.changeCounter({ type: "newPost", action: "reset" });
      } else if (this.currentView == "chat") {
        this.downloadAllChats({ networkId: this.getActiveId });
        this.changeCounter({ type: "newMsg", action: "reset" });
      } else if (this.currentView == "profile") {
        // this.downloadAllChats({ networkId: this.getActiveId });
      } else if (this.currentView == "events") {
        this.downloadAllEvents({ networkId: this.getActiveId });
        this.changeCounter({ type: "newEvent", action: "reset" });
      } else if (this.currentView == "groups") {
        this.downloadAllGroups({ networkId: this.getActiveId });
        this.changeCounter({ type: "newGroup", action: "reset" });
      }
      console.log("refreshing", this.currentView);
      event.target.complete();
    },
    changeView(id) {
      if (id == "posts") {
        this.currentView = "posts";
      } else if (id == "profile") {
        this.currentView = "profile";
      } else if (id == "chat") {
        this.currentView = "chat";
      } else if (id == "groups") {
        this.currentView = "groups";
      } else if (id == "events") {
        this.currentView = "events";
      }
    },
    async createNewPost() {
      let self = this;
      const modal = await modalController.create({
        component: NewPostModal,
        cssClass: "post-modal",
        componentProps: {
          eventId: 0,
          groupId: 0,
        },
      });

      modal.present();

      modal.onDidDismiss().then(async (data) => {
        // self.loadPosts();
        // debugger;
        if (data.data != undefined && data.data.created) {
          const toast = await toastController.create({
            message: "Přáspěvek byl přidán.",
            duration: 2000,
            position: "top",
          });
          toast.present();
        }
      });
    },
    async createNewChat() {
      let self = this;
      const modal = await modalController.create({
        component: NewChatModal,
        cssClass: "new-chat-modal",
        componentProps: {
          isNewChat: true,
        },
      });

      modal.present();

      modal.onDidDismiss().then(async (data) => {
        // self.loadPosts();
      });
    },
    async createNewGroup() {
      const modal = await modalController.create({
        component: NewGroupModal,
        cssClass: "group-modal",
        componentProps: {},
      });

      modal.present();

      modal.onDidDismiss().then(async (data) => {
        // self.loadPosts();
        console.log("DTA", data);
        if (data.data != undefined && data.data.created) {
          self.groupsView = "users";
          const toast = await toastController.create({
            message: "Vaše skupina byla vytvořena.",
            duration: 2000,
            position: "top",
          });
          toast.present();
        }
      });
    },
    async createNewEvent() {
      const modal = await modalController.create({
        component: NewEventModal,
        cssClass: "event-modal",
        componentProps: {},
      });

      modal.present();

      modal.onDidDismiss().then(async (data) => {
        // self.loadPosts();
        if (data.data != undefined && data.data.created) {
          self.eventView = "explore";
          const toast = await toastController.create({
            message: "Událost byla vytvořena.",
            duration: 2000,
            position: "top",
          });
          toast.present();
        }
      });
    },
  },
};
</script>

<style scoped>
ion-title {
  margin: 20px 0px;
}

.md ion-segment-button {
  min-width: auto;
}
#background-content {
  --background: #f2f2f7 !important;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
h2 {
  margin: 5px 0px;
}
.chip-controls {
  justify-content: flex-end;
  display: flex;
}
.md ion-badge {
  position: absolute;
  top: 8px;
  right: calc(50% - 18px);
}
.ios ion-badge {
  position: absolute;
  top: 6px;
  right: -4px;
  font-size: 10px;
  padding: 3px;
}
.selected-chip {
  border: 1px solid var(--ion-color-primary, #3880ff) !important;
}

/* @media screen and (min-width: 1024px) {
  .md ion-badge {
    position: absolute;
    top: 8px;
    right: 44%;
  }
} */
</style>
